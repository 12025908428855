<template>
  <div>
    <!-- Tambah To Soal -->
    <b-modal
      id="tambah-to-soal"
      size="xl"
      title="Tambah Soal"
      cancel-title="Close"
      ok-title="Simpan"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="submitSoal"
    >
      <b-form>
        <b-card-actions title="Jenis Soal" action-collapse>
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="form.jenis_soal"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="listJenisSoal"
                  :reduce="(jenisSoal) => jenisSoal.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions title="Pertanyaan" action-collapse>
          <quill-editor v-model="form.pertanyaan" />
        </b-card-actions>

        <!-- Kunci Jawaban untuk jenis soal Essay -->
        <b-card-actions
          v-if="form.jenis_soal === 'essay'"
          title="Kunci Jawaban"
          action-collapse
        >
          <quill-editor v-model="form.kunci_jawaban_essay" />
        </b-card-actions>

        <!-- Option Pernyataan for jenis_soal === 'checkbox' -->
        <b-card-actions
          v-if="form.jenis_soal === 'checkbox'"
          title="Pernyataan"
          action-collapse
        >
          <b-card
            v-for="(item, index) in form.pernyataan_checkbox"
            :key="index"
            class="pilihan-jawaban"
          >
            <b-card-actions
              bg-variant="light"
              :title="`Pernyataan ${index + 1}`"
              action-collapse
            >
              <quill-editor
                v-model="form.pernyataan_checkbox[index]"
                class="bg-white"
              />
            </b-card-actions>
          </b-card>

          <div class="tw-flex tw-justify-center">
            <b-button
              class="tw-rounded-[15px] tw-flex tw-gap-1 tw-justify-center tw-items-center"
              variant="primary"
              @click="tambahPernyataan"
            >
              <feather-icon icon="PlusIcon" />
              <span class="text-sm text-nowrap">Tambah Pernyataan</span>
            </b-button>
          </div>
        </b-card-actions>

        <!-- Option Kategori for jenis_soal === 'checkbox' -->
        <b-card-actions
          v-if="form.jenis_soal === 'checkbox'"
          title="Kategori"
          action-collapse
        >
          <b-card
            v-for="(item, index) in form.kategori_checkbox"
            :key="index"
            class="pilihan-jawaban"
          >
            <b-card-actions
              bg-variant="light"
              :title="`Kategori ${index + 1}`"
              action-collapse
            >
              <quill-editor
                v-model="form.kategori_checkbox[index]"
                class="bg-white"
              />
            </b-card-actions>
          </b-card>

          <div class="tw-flex tw-justify-center">
            <b-button
              class="tw-rounded-[15px] tw-flex tw-gap-1 tw-justify-center tw-items-center"
              variant="primary"
              @click="tambahKategori"
            >
              <feather-icon icon="PlusIcon" />
              <span class="text-sm text-nowrap">Tambah Kategori</span>
            </b-button>
          </div>
        </b-card-actions>

        <!--Setup kunci_jawaban_checkbox -->
        <b-card-actions
          v-if="form.jenis_soal === 'checkbox'"
          title="Kunci Jawaban Checkbox"
          action-collapse
        >
          <table-checkbox :data="pilCheckboxKunJaw" />
        </b-card-actions>

        <b-card-actions
          v-if="form.jenis_soal === 'pilihan_ganda'"
          title="Pilihan Jawaban"
          action-collapse
        >
          <b-card class="pilihan-jawaban">
            <b-card-actions
              bg-variant="light"
              title="Pilihan A"
              action-collapse
            >
              <b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.a"
              />
              <quill-editor v-model="form.pilihan.a" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions
              bg-variant="light"
              title="Pilihan B"
              action-collapse
            >
              <b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.b"
              />
              <quill-editor v-model="form.pilihan.b" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions
              bg-variant="light"
              title="Pilihan C"
              action-collapse
            >
              <b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.c"
              />
              <quill-editor v-model="form.pilihan.c" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions
              bg-variant="light"
              title="Pilihan D"
              action-collapse
            >
              <b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.d"
              />
              <quill-editor v-model="form.pilihan.d" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions
              bg-variant="light"
              title="Pilihan E"
              action-collapse
            >
              <b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.e"
              />
              <quill-editor v-model="form.pilihan.e" class="bg-white" />
            </b-card-actions>
          </b-card>
        </b-card-actions>

        <b-card-actions
          v-if="form.jenis_soal === 'pilihan_ganda'"
          title="Kunci Jawaban"
          action-collapse
        >
          <b-form-group>
            <v-select
              v-model="form.kunci_jawaban"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="pilihanJawaban"
              :reduce="(kj) => kj.title"
            />
          </b-form-group>
        </b-card-actions>

        <b-card-actions title="Pembahasan" action-collapse>
          <quill-editor class="mb-2" v-model="form.pembahasan.text" />

          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="VideoIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan link youtube pembahasan"
              v-model="form.pembahasan.video"
            />
          </b-input-group>
        </b-card-actions>

        <b-card-actions title="Kode" action-collapse>
          <b-row>
            <b-col>
              <b-form-input placeholder="Masukkan kode" v-model="form.kode" />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions title="Mata Pelajaran" action-collapse>
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="form.mapel_soal_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="listMapel"
                  :reduce="(mapel) => mapel.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions title="Paket" action-collapse>
          <b-form-group>
            <v-select
              v-model="form.id_mapel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="listSoalMapped"
              :reduce="(mapel) => mapel.value"
              multiple
            />
          </b-form-group>
        </b-card-actions>
      </b-form>
    </b-modal>

    <!-- Edit To Soal -->
    <b-modal
      id="edit-to-soal"
      size="xl"
      title="Edit Soal"
      cancel-title="Close"
      ok-title="Simpan"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      @ok="editSoal"
    >
      <template v-if="isLoadingDetail">
        <div class="my-2 text-center text-danger">
          <b-spinner class="mr-1 align-middle"></b-spinner>
          <strong>Sedang memuat data...</strong>
        </div>
      </template>

      <b-form v-else>
        <b-card-actions title="Jenis Soal" action-collapse>
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="form.jenis_soal"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="listJenisSoal"
                  :reduce="(jenisSoal) => jenisSoal.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions title="Pertanyaan" action-collapse>
          <quill-editor v-model="form.pertanyaan" />
        </b-card-actions>

        <!-- Kunci Jawaban untuk jenis soal Essay -->
        <b-card-actions
          v-if="form.jenis_soal === 'essay'"
          title="Kunci Jawaban"
          action-collapse
        >
          <quill-editor v-model="form.kunci_jawaban_essay" />
        </b-card-actions>

        <!-- Option Pernyataan for jenis_soal === 'checkbox' -->
        <b-card-actions
          v-if="form.jenis_soal === 'checkbox'"
          title="Pernyataan"
          action-collapse
        >
          <b-card
            v-for="(item, index) in form.pernyataan_checkbox"
            :key="index"
            class="pilihan-jawaban"
          >
            <b-card-actions
              bg-variant="light"
              :title="`Pernyataan ${index + 1}`"
              action-collapse
            >
              <quill-editor
                v-model="form.pernyataan_checkbox[index]"
                class="bg-white"
              />
            </b-card-actions>
          </b-card>

          <div class="tw-flex tw-justify-center">
            <b-button
              class="tw-rounded-[15px] tw-flex tw-gap-1 tw-justify-center tw-items-center"
              variant="primary"
              @click="tambahPernyataan"
            >
              <feather-icon icon="PlusIcon" />
              <span class="text-sm text-nowrap">Tambah Pernyataan</span>
            </b-button>
          </div>
        </b-card-actions>

        <!-- Option Kategori for jenis_soal === 'checkbox' -->
        <b-card-actions
          v-if="form.jenis_soal === 'checkbox'"
          title="Kategori"
          action-collapse
        >
          <b-card
            v-for="(item, index) in form.kategori_checkbox"
            :key="index"
            class="pilihan-jawaban"
          >
            <b-card-actions
              bg-variant="light"
              :title="`Kategori ${index + 1}`"
              action-collapse
            >
              <quill-editor
                v-model="form.kategori_checkbox[index]"
                class="bg-white"
              />
            </b-card-actions>
          </b-card>

          <div class="tw-flex tw-justify-center">
            <b-button
              class="tw-rounded-[15px] tw-flex tw-gap-1 tw-justify-center tw-items-center"
              variant="primary"
              @click="tambahKategori"
            >
              <feather-icon icon="PlusIcon" />
              <span class="text-sm text-nowrap">Tambah Kategori</span>
            </b-button>
          </div>
        </b-card-actions>

        <!--Setup kunci_jawaban_checkbox -->
        <b-card-actions
          v-if="form.jenis_soal === 'checkbox'"
          title="Kunci Jawaban Checkbox"
          action-collapse
        >
          <table-checkbox :data="pilCheckboxKunJaw" />
        </b-card-actions>

        <!-- Pilihan jawaban hanya tersedia dengan jenis soal pg -->
        <b-card-actions
          v-if="form.jenis_soal === 'pilihan_ganda'"
          title="Pilihan Jawaban"
          action-collapse
        >
          <b-card class="pilihan-jawaban">
            <b-card-actions
              bg-variant="light"
              title="Pilihan A"
              action-collapse
            >
              <b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.a"
              />
              <quill-editor v-model="form.pilihan.a" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions bg-variant="light" title="Pilihan B" action-collapse
              ><b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.b"
              />
              <quill-editor v-model="form.pilihan.b" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions bg-variant="light" title="Pilihan C" action-collapse
              ><b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.c"
              />
              <quill-editor v-model="form.pilihan.c" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions bg-variant="light" title="Pilihan D" action-collapse
              ><b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.d"
              />
              <quill-editor v-model="form.pilihan.d" class="bg-white" />
            </b-card-actions>
          </b-card>

          <b-card class="pilihan-jawaban">
            <b-card-actions bg-variant="light" title="Pilihan E" action-collapse
              ><b-form-input
                class="w-auto mb-1"
                placeholder="Masukkan bobot"
                type="number"
                v-model="form.bobot.e"
              />
              <quill-editor v-model="form.pilihan.e" class="bg-white" />
            </b-card-actions>
          </b-card>
        </b-card-actions>

        <!-- set kunci jawaban for jenis_soal === "pilihan_ganda" -->
        <b-card-actions
          v-if="form.jenis_soal === 'pilihan_ganda'"
          title="Kunci Jawaban"
          action-collapse
        >
          <b-form-group>
            <v-select
              v-model="form.kunci_jawaban"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="pilihanJawaban"
              :reduce="(kj) => kj.title"
            />
          </b-form-group>
        </b-card-actions>

        <b-card-actions title="Pembahasan" action-collapse>
          <quill-editor class="mb-2" v-model="form.pembahasan.text" />

          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="VideoIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan link youtube pembahasan"
              v-model="form.pembahasan.video"
            />
          </b-input-group>
        </b-card-actions>

        <b-card-actions title="Kode" action-collapse>
          <b-row>
            <b-col>
              <b-form-input placeholder="Masukkan Kode" v-model="form.kode" />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions title="Mata Pelajaran" action-collapse>
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="form.mapel_soal_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="listMapel"
                  :reduce="(mapel) => mapel.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions title="Paket" action-collapse>
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="form.id_mapel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="listSoalMapped"
                  :reduce="(mapel) => mapel.value"
                  multiple
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-form>
    </b-modal>

    <!-- Delete Try Out -->
    <b-modal
      id="hapus-to-soal"
      cancel-variant="outline-secondary"
      ok-title="Hapus"
      cancel-title="Close"
      centered
      title="Hapus Soal"
      @ok="deleteSoal(form.id_mapel)"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda akan menghapus Soal ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>

    <div class="my-2">
      <!-- Table Top -->
      <div
        style="gap: 12px"
        class="d-flex align-items-center justify-content-between"
      >
        <search-input
          :placeholder="'Cari berdasarkan ID atau Kode Soal..'"
          :onInput="onSearch"
        />
        <div
          style="gap: 12px"
          class="d-flex align-items-center justify-content-end"
        >
          <!-- <b-form-input v-model="searchQuery" class="mr-1 d-inline-block" placeholder="Search..." /> -->
          <b-button
            style="border-radius: 15px; height: 48px"
            variant="primary"
            @click="fetchSoal()"
          >
            <span class="text-sm text-nowrap">Refresh Data</span>
          </b-button>

          <b-button
            style="border-radius: 15px; height: 48px"
            variant="primary"
            v-b-modal.tambah-to-soal
          >
            <span class="text-nowrap">Tambah Soal</span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Filter By -->
    <div class="tw-inline-flex tw-mb-6">
      <custom-filter
        :data="filterList"
        :handleChange="handleChange"
        :filterValue="filterValue"
        :initialValue="initialValue"
      />
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
        :busy="isLoading"
        ref="refSoalListTable"
        class="position-relative"
        :items="listSoal"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(pertanyaan)="data">
          <!-- <div v-html="data.item.pertanyaan"></div> -->
          <vue-mathjax
            :safe="false"
            :formula="data.item.pertanyaan"
          ></vue-mathjax>
        </template>

        <template #cell(jenis_soal)="data">
          <p class="tw-text-[#6F7981] tw-capitalize">
            {{
              data.item.jenis_soal === "pilihan_ganda"
                ? "pilihan ganda"
                : data.item.jenis_soal
            }}
          </p>
        </template>

        <template #cell(created_at)="data">
          <p>
            {{ formatDate(data.value) }}
          </p>
        </template>

        <template #cell(updated_at)="data">
          <p>
            {{ formatDate(data.value) }}
          </p>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'bank-soal-detail', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detail Soal</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="getToSoal(data.item.id)"
              v-b-modal.edit-to-soal
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.hapus-to-soal
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="totalPage"
          :total="totalData"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BCardText,
  BSpinner,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BPaginationNav,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onMounted, computed, watch } from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { VueMathjax } from "vue-mathjax";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useRouter, formatDate } from "@core/utils/utils";

import repository from "@repofactory";
const repoSoal = repository.get("soalRepository");
const repoMapel = repository.get("mapelRepository");
const repoKategoriTO = repository.get("kategoriSoalRepository");
const repoPaketMapel = repository.get("paketMapelRepository");

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import SearchInput from "@/@core/components/base/SearchInput.vue";
import debounce from "lodash.debounce";
import CustomFilter from "@/@core/components/base/CustomFilter.vue";
import TableCheckbox from "@/@core/components/base/TableCheckbox.vue";
import Pagination from "@/@core/components/base/Pagination.vue";

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BSpinner,
    BForm,
    BFormGroup,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VueMathjax,
    BCardActions,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    quillEditor,
    BPaginationNav,
    FeatherIcon,
    SearchInput,
    CustomFilter,
    BFormRadio,
    TableCheckbox,
    Pagination,
  },
  setup() {
    const { route } = useRouter();

    const listSoal = ref([]);
    const listPaketMapel = ref([]);
    const toast = useToast();
    const word = ref("");
    const listJenisSoal = ref([
      {
        title: "Pilihan Ganda",
        value: "pilihan_ganda",
      },
      {
        title: "Checkbox",
        value: "checkbox",
      },
      {
        title: "Essay",
        value: "essay",
      },
    ]);

    // filter
    const filterList = ref([
      {
        name: "Mata Pelajaran",
        list: [],
      },
      {
        name: "Kategori",
        list: [],
      },
      {
        name: "Paket",
        list: [],
      },
    ]);

    const initialValue = ref({
      "Mata Pelajaran": null,
      Kategori: null,
      Paket: null,
    });

    const filterValue = ref({
      "Mata Pelajaran": null,
      Kategori: null,
      Paket: null,
    });

    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const isError = ref(false);

    const isLoadingDetail = ref(false);

    let totalPage = ref();
    let totalData = ref();
    let from = ref();
    let to = ref();

    const listMapel = ref([])

    let linkGen = (pageNum) => {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    };

    watch(
      [() => route.value.query.page, word, () => filterValue.value],
      debounce(
        () =>
          fetchSoal(
            route.value.query.page,
            word.value,
            filterValue.value["Mata Pelajaran"],
            filterValue.value["Kategori"],
            filterValue.value["Paket"]
          ),
        500
      )
    );

    const form = ref({
      id: null,
      kode: "",
      pertanyaan: "",
      jenis_soal: "pilihan_ganda",
      pilihan: {
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
      },
      bobot: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
      },
      // value for storing checkbox pernyataan and jawaban
      pernyataan_checkbox: [],
      kategori_checkbox: [],

      kunci_jawaban: "",
      kunci_jawaban_essay: "",
      kunci_jawaban_checkbox: null,

      id_mapel: [],
      pembahasan: {
        text: "",
        video: "",
      },
      index: null,
      mapel_soal_id: null
    });

    const pilCheckboxKunJaw = ref([]);

    watch(
      [
        () => form.value.pernyataan_checkbox,
        () => form.value.kategori_checkbox,
      ],
      () => {
        pilCheckboxKunJaw.value = form.value.pernyataan_checkbox.map(
          (item, index) => {
            let data = {
              pertanyaan: item,
              selected: pilCheckboxKunJaw.value[index]?.selected,

              jawaban: [...form.value.kategori_checkbox],
            };
            return data;
          }
        );
      },
      { deep: true }
    );

    // Columns table for kunci_jawaban_checkbox
    const checkboxColumns = computed(() => {
      const col = [
        {
          key: "pertanyaan",
          label: "PERTANYAAN",
          thStyle: { fontWeight: 600, fontSize: "14px" },
        },
      ];

      form.value.kategori_checkbox.forEach((item) => {
        col.push({
          key: item,
          label: item,
          thStyle: { fontWeight: 600, fontSize: "14px" },
        });
      });

      return col;
    });

    const listSoalMapped = computed(() => {
      return listPaketMapel.value.map((val) => {
        return {
          title: val.nama.toUpperCase(),
          value: parseInt(val.id),
        };
      });
    });

    const content = ref("");
    const selected = ref("");

    const option = [
      { title: "Square" },
      { title: "Rectangle" },
      { title: "Rombo" },
      { title: "Romboid" },
    ];

    const tableColumns = [
      {
        key: "id",
        label: "ID",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "kode",
        label: "KODE",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "pertanyaan",
        label: "PERTANYAAN",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "jenis_soal",
        label: "JENIS SOAL",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "mata_pelajaran",
        label: "MATA PELAJARAN",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "kategori_soal",
        label: "KATEGORI SOAL",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "paket",
        label: "PAKET",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "created_at",
        label: "DIBUAT",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      {
        key: "updated_at",
        label: "DIUBAH",
        thStyle: { fontWeight: 600, fontSize: "14px" },
      },
      { key: "actions", thStyle: { fontWeight: 600, fontSize: "14px" } },
    ];

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const pilihanJawaban = [
      { title: "A" },
      { title: "B" },
      { title: "C" },
      { title: "D" },
      { title: "E" },
    ];

    const resetModal = () => {
      form.value = {
        pertanyaan: "",
        jenis_soal: "pilihan_ganda",
        pilihan: {
          a: "",
          b: "",
          c: "",
          d: "",
          e: "",
        },
        bobot: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
      },
        kode: "",

        pernyataan_checkbox: [],
        kategori_checkbox: [],

        kunci_jawaban: "",
        kunci_jawaban_essay: "",
        kunci_jawaban_checkbox: null,

        id_mapel: [],
        pembahasan: {
          text: "",
          video: "",
        },
        index: null,
        mapel_soal_id: null
      };
    };

    const getToSoal = async (id) => {
      try {
        isLoadingDetail.value = true;
        const res = await repoSoal.show(id);
        const data = res.data.data;

        // fill edit form
        form.value = {
          id,
          jenis_soal: data.jenis_soal,
          kode: data.kode,
          id_mapel: (data.paket_mapels || []).map(item => item.paket_mapel_id),
          pertanyaan: data.pertanyaan,
          mapel_soal_id: data.mapel_soal_id,
          pilihan: {
            a: data.pil_a,
            b: data.pil_b,
            c: data.pil_c,
            d: data.pil_d,
            e: data.pil_e,
          },
          bobot: {
            a: data.bobot_nilai_a || 0,
            b: data.bobot_nilai_b || 0,
            c: data.bobot_nilai_c || 0,
            d: data.bobot_nilai_d || 0,
            e: data.bobot_nilai_e || 0,
          },
          kunci_jawaban: data.kunci_jawaban,
          kunci_jawaban_essay: data.kunci_jawaban_essay,
          kunci_jawaban_checkbox: JSON.parse(data.kunci_jawaban_checkbox),
          pembahasan: {
            text: data.pembahasan_text,
            video: data.pembahasan_video,
          },
        };

        if (data.jenis_soal === "checkbox") {
          const pilCheckbox = JSON.parse(data.pil_checkbox);
          const kunjawCheckbox = JSON.parse(data.kunci_jawaban_checkbox).map(
            (item) => {
              let data = {
                pertanyaan: item.pertanyaan,
                selected: Object.keys(
                  item.jawaban.find((kategoris) => {
                    const key = Object.keys(kategoris)[0];
                    return kategoris[key] === true;
                  })
                )[0],
              };

              return data;
            }
          );

          const pernyataan_checkbox = [
            ...pilCheckbox.map((item) => item.pertanyaan),
          ];
          const kategori_checkbox = [
            ...pilCheckbox[0].jawaban.map((item) => Object.keys(item)[0]),
          ];

          form.value = {
            ...form.value,
            pernyataan_checkbox,
            kategori_checkbox,
          };

          pilCheckboxKunJaw.value = kunjawCheckbox;
        }
      } catch (error) {
        showToast(
          "Error",
          error.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
      } finally {
        isLoadingDetail.value = false;
      }
    };

    const setTargetedId = (id) => {
      form.value.id_mapel = id;
    };

    const fetchSoal = async (
      page = 1,
      word = "",
      mataPelajaran,
      kategori,
      paket
    ) => {
      isLoading.value = true;
      await repoSoal
        .get(page, word, mataPelajaran, kategori, paket)
        .then(function (response) {
          isLoading.value = false;
          listSoal.value = response.data.data.data;

          from.value = response.data.data.from;
          to.value = response.data.data.to;
          totalPage.value = response.data.data.last_page;
          totalData.value = response.data.data.total;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const fetchMapel = async () => {
      isLoading.value = true;
      await repoMapel
        .get()
        .then(function (response) {
          const mapelFilterList = response.data.data.map((item) => ({
            label: item.nama.toUpperCase(),
            value: item.nama.toUpperCase(),
          }));

          listMapel.value = response.data.data.map(item => ({
            label: item.nama.toUpperCase(),
            value: item.id
          }))

          filterList.value[0].list = [...mapelFilterList];
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const fetchKategoriSoal = async () => {
      isLoading.value = true;
      const response = await repoKategoriTO.get();
      filterList.value[1].list = response.data.data.map((item) => ({
        label: item.nama,
        value: item.nama,
      }));
      isLoading.value = false;
    };

    const fetchPaketMapel = async () => {
      isLoading.value = true;
      const response = await repoPaketMapel.get();

      listPaketMapel.value = response.data.data.data;
      filterList.value[2].list = response.data.data.data.map((item) => ({
        label: item.nama,
        value: item.nama,
      }));
      isLoading.value = false;
    };

    const submitSoal = async (modal) => {
      modal.preventDefault();
      isSubmitting.value = true;

      let payload = {
        // property needed for every jenis_soal value
        kode: form.value.kode,
        mapel_soal_id: form.value.mapel_soal_id,
        paket_mapel_id: form.value.id_mapel,
        pertanyaan: form.value.pertanyaan,
        jenis_soal: form.value.jenis_soal,
        pembahasan_text: form.value.pembahasan.text,
        pembahasan_video: form.value.pembahasan.video,
      };

      if (form.value.jenis_soal === "pilihan_ganda") {
        payload = {
          ...payload,
          pil_a: form.value.pilihan.a,
          pil_b: form.value.pilihan.b,
          pil_c: form.value.pilihan.c,
          pil_d: form.value.pilihan.d,
          pil_e: form.value.pilihan.e,
          bobot_nilai_a: form.value.bobot.a,
          bobot_nilai_b: form.value.bobot.b,
          bobot_nilai_c: form.value.bobot.c,
          bobot_nilai_d: form.value.bobot.d,
          bobot_nilai_e: form.value.bobot.e,
          kunci_jawaban: form.value.kunci_jawaban,
        };
      } else if (form.value.jenis_soal === "essay") {
        payload = {
          ...payload,
          kunci_jawaban_essay: form.value.kunci_jawaban_essay,
        };
      } else {
        const pil_checkbox = [];
        const kunjawCheckbox = [];

        form.value.pernyataan_checkbox.forEach((item, index) => {
          const data = {
            pertanyaan: item,
            jawaban: form.value.kategori_checkbox.map((kategori) => ({
              [kategori]: false,
            })),
          };

          pil_checkbox.push(data);

          kunjawCheckbox.push({
            ...data,
            jawaban: form.value.kategori_checkbox.map((kategori) => ({
              [kategori]: pilCheckboxKunJaw.value[index].selected === kategori,
            })),
          });
        });

        const jsonPilCheckbox = JSON.stringify(pil_checkbox);
        const jsonKunjawCheckbox = JSON.stringify(kunjawCheckbox);

        payload = {
          ...payload,
          pil_checkbox: jsonPilCheckbox,
          kunci_jawaban_checkbox: jsonKunjawCheckbox,
        };
      }

      await repoSoal
        .create(payload)
        .then((response) => {
          // listSoal.value.push(response.data.data);

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
          modal.vueTarget.hide();
          fetchSoal()
        })
        .catch((error) => {
          let errorMessage = "Gagal Menyimpan Soal";

          // if response.data.error is null handler
          if(error.response.data.error) {
            const keys = Object.keys(error.response.data.error)
            errorMessage = error.response.data.error[keys[0]][0]
          }

          if (error.response) {
            showToast(
              "Error",
              errorMessage,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          // resetModal();
        });
    };

    const editSoal = async (modal) => {
      modal.preventDefault();
      isSubmitting.value = true;

      let payload = {
        // property needed for every jenis_soal value
        kode: form.value.kode,
        mapel_soal_id: form.value.mapel_soal_id,
        paket_mapel_id: form.value.id_mapel,
        pertanyaan: form.value.pertanyaan,
        jenis_soal: form.value.jenis_soal,
        pembahasan_text: form.value.pembahasan.text,
        pembahasan_video: form.value.pembahasan.video,
      };

      if (form.value.jenis_soal === "pilihan_ganda") {
        payload = {
          ...payload,
          pil_a: form.value.pilihan.a,
          pil_b: form.value.pilihan.b,
          pil_c: form.value.pilihan.c,
          pil_d: form.value.pilihan.d,
          pil_e: form.value.pilihan.e,
          bobot_nilai_a: form.value.bobot.a,
          bobot_nilai_b: form.value.bobot.b,
          bobot_nilai_c: form.value.bobot.c,
          bobot_nilai_d: form.value.bobot.d,
          bobot_nilai_e: form.value.bobot.e,
          kunci_jawaban: form.value.kunci_jawaban,
        };
      } else if (form.value.jenis_soal === "essay") {
        payload = {
          ...payload,
          kunci_jawaban_essay: form.value.kunci_jawaban_essay,
        };
      } else {
        const pil_checkbox = [];
        const kunjawCheckbox = [];

        form.value.pernyataan_checkbox.forEach((item, index) => {
          const data = {
            pertanyaan: item,
            jawaban: form.value.kategori_checkbox.map((kategori) => ({
              [kategori]: false,
            })),
          };

          pil_checkbox.push(data);

          kunjawCheckbox.push({
            ...data,
            jawaban: form.value.kategori_checkbox.map((kategori) => ({
              [kategori]: pilCheckboxKunJaw.value[index].selected === kategori,
            })),
          });
        });

        const jsonPilCheckbox = JSON.stringify(pil_checkbox);
        const jsonKunjawCheckbox = JSON.stringify(kunjawCheckbox);

        payload = {
          ...payload,
          pil_checkbox: jsonPilCheckbox,
          kunci_jawaban_checkbox: jsonKunjawCheckbox,
        };
      }

      await repoSoal
        .update(payload, form.value.id)
        .then((response) => {
          fetchSoal();

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
          modal.vueTarget.hide();
        })
        .catch((error) => {
          let errorMessage = "Gagal Mengedit Soal";

          // if response.data.error is null handler
          if(error.response.data.error) {
            const keys = Object.keys(error.response.data.error)
            errorMessage = error.response.data.error[keys[0]][0]
          }
          if (error.response) {
            showToast(
              "Error",
              errorMessage,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const deleteSoal = async (id) => {
      isSubmitting.value = true;

      await repoSoal
        .delete(id)
        .then((response) => {
          fetchSoal();
          showToast("Notifikasi", response.data.message, "primary");
          resetModal();

          isSubmitting.value = false;
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const onSearch = (e) => {
      word.value = e.currentTarget.value;
    };

    const handleChange = (value) => {
      filterValue.value = { ...value };
    };

    const tambahPernyataan = () => {
      form.value = {
        ...form.value,
        pernyataan_checkbox: [...form.value.pernyataan_checkbox, ""],
      };
    };

    const tambahKategori = () => {
      form.value = {
        ...form.value,
        kategori_checkbox: [...form.value.kategori_checkbox, ""],
      };
    };

    onMounted(async () => {
      fetchSoal();
      fetchMapel();
      fetchKategoriSoal();
      fetchPaketMapel();
    });

    return {
      // state
      word,
      filterList,
      isError,
      isLoading,
      isLoadingDetail,
      isSubmitting,
      listSoal,
      option,
      selected,
      content,
      tableColumns,
      listSoalMapped,
      totalPage,
      from,
      to,
      totalData,
      form,
      filterValue,
      initialValue,
      listJenisSoal,
      checkboxColumns,
      pilCheckboxKunJaw,
      listMapel,

      // method
      fetchSoal,
      resetModal,
      submitSoal,
      editSoal,
      pilihanJawaban,
      getToSoal,
      deleteSoal,
      linkGen,
      onSearch,
      handleChange,
      setTargetedId,
      tambahPernyataan,
      tambahKategori,
      formatDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.border-x {
  border-right: 0.41px solid #d5d5d5;
}

.no-focus-outline:focus {
  box-shadow: none; /* Remove any box shadow on focus */
  outline: none; /* Remove the default outline on focus */
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.pilihan-jawaban {
  margin-bottom: 0 !important;
}
</style>
